export default {
    name: 'Init',
    data: function () {
        return {
            activeStep: 0,
            steps: [{
                img: 'detective.svg',
                title: 'Discover employers that aren’t shady.',
                desc: 'You’ve got moral standards, those should match with your employer.'
            }, {
                img: 'feature.svg',
                title: 'Find the ones that match your values.',
                desc: 'Filter through badges and rankings to easily set your requirements.'
            }, {
                img: 'apply.svg',
                title: 'Meet your match and make a move.',
                desc: 'Find your next employer by exploring relevant open positions.'
            }],
            event: null
        }
    },
    methods: {
        switchStep: function () {
            if (this.activeStep < this.steps.length - 1) this.activeStep++
            else this.activeStep = 0
        }
    },
    created: function () {
        this.event = this.$route.query.event
    }
}